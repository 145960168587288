(()=> {

const innerTimeline = document.querySelector('.timeline-inner');
const contents = innerTimeline.querySelectorAll('.contents');
const timelineUl = document.querySelector('.timeline-ul');

let lastContent = Math.round(contents.length/2);
let pixels = 0;

for(const content of contents) {
	const timelineContent = content.querySelector('.timeline-content');
	const timelineBtn = content.querySelector('.timeline-button');
	
	timelineBtn.addEventListener('click', () => {
		// console.log(timelineContent);
		timelineContent.classList.toggle('active');

		moveContent(content.id, content.offsetWidth);
		innerTimeline.style.transform = 'translateX(' + pixels + 'px)';
	});
}

const moveContent = (id, width) => {
	let coeff = lastContent - id;

	//column gap + width of content
	pixels += coeff * (200 + width);

	console.log(pixels);

	lastContent = id;
}

})();

